import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

import SuggestIdeasStep from "./steps/SuggestIdeasStep";
import SuggestParticipantsStep from "./steps/SuggestParticipantsStep";
import SuggestFeaturesStep from "./steps/SuggestFeaturesStep";
import CategorizeAndStreamlineFeaturesStep from "./steps/CategorizeAndStreamlineFeaturesStep";
import PerformDotVotingStep from "./steps/PerformDotVotingStep";
import PerformMoSCoWAnalysisStep from "./steps/PerformMoSCoWAnalysisStep";
import DefineMVPFeaturesStep from "./steps/DefineMVPFeaturesStep";
import DefineFullProductFeaturesStep from "./steps/DefineFullProductFeaturesStep";
import GenerateWorkshopReportStep from "./steps/GenerateWorkshopReportStep";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "16px",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
}));

const WorkshopHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const WorkshopContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const steps = [
  { label: "Pick Idea", state: "suggestIdeas" },
  { label: "Select Participants", state: "suggestParticipants" },
  { label: "Suggest Features", state: "suggestFeatures" },
  { label: "Categorize & Streamline", state: "categorizeAndStreamlineFeatures" },
  { label: "Dot Voting", state: "performDotVoting" },
  { label: "MoSCoW Analysis", state: "performMoSCoWAnalysis" },
  { label: "Define MVP", state: "defineMVPFeatures" },
  { label: "Define Full Product", state: "defineFullProductFeatures" },
  { label: "Generate Report", state: "generateWorkshopReport" },
];

function WorkshopVisualizer({ data, updateWorkshop }) {
  useEffect(() => {
    if (data?.currentState || data?.nextStep) {
      window.scrollTo({ top: 0 });
    }
  }, [data?.currentState, data?.nextStep]);

  if (!data) {
    return (
      <StyledPaper component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
          <CircularProgress />
        </Box>
      </StyledPaper>
    );
  }

  const getStepIndex = () => {
    const index = steps.findIndex(step => step.state === data.currentState);
    return index === -1 ? steps.length - 1 : index;
  };

  const renderStep = () => {
    const step = data.nextStep || data.currentState;

    switch (step) {
      case "suggestIdeas":
        return <SuggestIdeasStep data={data} updateWorkshop={updateWorkshop} />;
      case "suggestParticipants":
        return <SuggestParticipantsStep data={data} updateWorkshop={updateWorkshop} />;
      case "suggestFeatures":
        return <SuggestFeaturesStep data={data} updateWorkshop={updateWorkshop} />;
      case "categorizeAndStreamlineFeatures":
        return <CategorizeAndStreamlineFeaturesStep data={data} updateWorkshop={updateWorkshop} />;
      case "performDotVoting":
        return <PerformDotVotingStep data={data} updateWorkshop={updateWorkshop} />;
      case "performMoSCoWAnalysis":
        return <PerformMoSCoWAnalysisStep data={data} updateWorkshop={updateWorkshop} />;
      case "defineMVPFeatures":
        return <DefineMVPFeaturesStep data={data} updateWorkshop={updateWorkshop} />;
      case "defineFullProductFeatures":
        return <DefineFullProductFeaturesStep data={data} updateWorkshop={updateWorkshop} />;
      case "generateWorkshopReport":
      case "reportGenerated":
      case "completed":
        return <GenerateWorkshopReportStep data={data} updateWorkshop={updateWorkshop} />;
      default:
        return <Typography>Current step: {data.currentState}</Typography>;
    }
  };

  return (
    <StyledPaper component={motion.div} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
      <WorkshopHeader>
        <Typography variant="h4" gutterBottom>
          {data.area || data.ideaName || "Untitled Workshop"}
        </Typography>
        <Typography variant="body1">
          {data.area && <><strong>Area:</strong> {data.area}</>}
        </Typography>
        {data.ideaName && (
          <>
            <Typography variant="body1">
              <strong>Idea:</strong> {data.ideaName}
            </Typography>
            <Typography variant="body2">{data.idea}</Typography>
          </>
        )}
      </WorkshopHeader>
      <WorkshopContent>
        <Stepper activeStep={getStepIndex()} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 4 }}>{renderStep()}</Box>
      </WorkshopContent>
    </StyledPaper>
  );
}

export default WorkshopVisualizer;
