import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function InlineProgress({ message }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
      <CircularProgress size={24} sx={{ mr: 2 }} />
      <Typography>{message}</Typography>
    </Box>
  );
}

export default InlineProgress;