import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FlagIcon from '@mui/icons-material/Flag';
import TimerIcon from '@mui/icons-material/Timer';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import InlineProgress from '../components/InlineProgress';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: theme.spacing(1, 3),
  textTransform: 'none',
  fontWeight: 600,
}));

const EffortChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 600,
  fontSize: '0.875rem',
}));

function DefineMVPFeaturesStep({ data, updateWorkshop }) {
  const handleContinue = () => {
    updateWorkshop(data.id, { nextStep: 'defineFullProductFeatures' });
  };

  return (
    <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 600 }}>
        <FlagIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
        MVP Features
      </Typography>
      {data.nextStep === 'defineMVPFeatures' ? (
        <InlineProgress message="Defining MVP features..." />
      ) : data.mvpFeatures ? (
        <>
          <AnimatePresence>
            <List>
              {data.mvpFeatures.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <StyledPaper elevation={3} sx={{ mb: 3, p: 3 }}>
                    <ListItem alignItems="flex-start" sx={{ flexDirection: 'column', p: 0 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                        <Typography variant="h6">{feature.shortDescription}</Typography>
                        <EffortChip 
                          label={`Effort: ${feature.estimatedEffort}`} 
                          icon={<TimerIcon />} 
                        />
                      </Box>
                      <ListItemText
                        secondary={
                          <>
                            <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 1 }}>
                              {feature.longDescription}
                            </Typography>
                            <Typography variant="body2" color="text.primary" sx={{ mt: 1 }}>
                              <strong>Implementation Details:</strong>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {feature.implementationDetails}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  </StyledPaper>
                </motion.div>
              ))}
            </List>
          </AnimatePresence>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleContinue}
              endIcon={<ArrowForwardIcon />}
            >
              Continue to Define Full Product Features
            </StyledButton>
          </Box>
        </>
      ) : (
        <Typography variant="body1" color="text.secondary">
          No MVP features defined yet.
        </Typography>
      )}
    </Box>
  );
}

export default DefineMVPFeaturesStep;