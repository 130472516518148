import { createTheme } from "@mui/material";

const workshopTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0A84FF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF375F",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#F2F2F7",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#6E6E73",
    },
    divider: "#C6C6C8",
  },
  typography: {
    fontFamily: "'Nunito', 'Roboto', 'Helvetica', 'Arial', sans-serif", // Replaced SF Pro Display with Nunito
    h1: {
      fontFamily: "'Leckerli One', sans-serif", // Updated fontFamily
      fontSize: "3rem",
      fontWeight: 400,
      fontStyle: "normal",
      color: "#000000",
    },
    h2: {
      fontFamily: "'Nunito', sans-serif", // Updated fontFamily
      fontSize: "2.5rem",
      fontWeight: 600,
      letterSpacing: "-0.00833em",
      lineHeight: 1.3,
      color: "#000000",
    },
    h3: {
      fontFamily: "'Nunito', sans-serif", // Updated fontFamily
      fontSize: "2rem",
      fontWeight: 600,
      letterSpacing: "0em",
      lineHeight: 1.4,
      color: "#000000",
    },
    h4: {
      fontFamily: "'Nunito', sans-serif", // Updated fontFamily
      fontSize: "1.75rem",
      fontWeight: 600,
      letterSpacing: "0.00735em",
      lineHeight: 1.5,
      color: "#000000",
    },
    h5: {
      fontFamily: "'Nunito', sans-serif", // Updated fontFamily
      fontSize: "1.5rem",
      fontWeight: 500,
      letterSpacing: "0em",
      lineHeight: 1.6,
      color: "#000000",
    },
    h6: {
      fontFamily: "'Nunito', sans-serif", // Updated fontFamily
      fontSize: "1.25rem",
      fontWeight: 500,
      letterSpacing: "0.0075em",
      lineHeight: 1.7,
      color: "#000000",
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
      letterSpacing: "0.00938em",
      lineHeight: 1.75,
      color: "#6E6E73",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      letterSpacing: "0.00714em",
      lineHeight: 1.57,
      color: "#6E6E73",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      letterSpacing: "0.00938em",
      lineHeight: 1.5,
      color: "#1D1D1F",
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      letterSpacing: "0.01071em",
      lineHeight: 1.43,
      color: "#1D1D1F",
    },
    button: {
      fontFamily: "'Nunito', sans-serif", // Updated fontFamily
      textTransform: "none",
      fontWeight: 600,
      letterSpacing: "0.02857em",
      lineHeight: 1.75,
      color: "#0A84FF",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      letterSpacing: "0.03333em",
      lineHeight: 1.66,
      color: "#6E6E73",
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 400,
      letterSpacing: "0.16667em",
      lineHeight: 2.66,
      textTransform: "uppercase",
      color: "#6E6E73",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');
        
        body {
          background-color: #F2F2F7;
          color: #000000;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          padding: "10px 20px",
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          color: "#000000",
          borderRadius: "12px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          color: "#000000",
          boxShadow: "none",
          borderBottom: "1px solid #C6C6C8",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0 20px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#C6C6C8",
        },
      },
    },
  },
});

export default workshopTheme;
