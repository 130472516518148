import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InlineProgress from "../components/InlineProgress";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "12px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px",
  padding: theme.spacing(1, 3),
  textTransform: "none",
  fontWeight: 600,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "12px",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.3)",
  },
}));

function SuggestParticipantsStep({ data, updateWorkshop }) {
  const [editingParticipant, setEditingParticipant] = useState(null);

  const handleSelectParticipant = (participant) => {
    const updatedParticipants = [...(data.participants || []), participant];
    updateWorkshop(data.id, {
      participants: updatedParticipants,
    });
  };

  const handleSelectAllParticipants = () => {
    const unselectedParticipants = data.suggestedParticipants.filter(
      (participant) => !isParticipantSelected(participant)
    );
    const updatedParticipants = [
      ...(data.participants || []),
      ...unselectedParticipants,
    ];
    updateWorkshop(data.id, {
      participants: updatedParticipants,
    });
  };

  const handleUnselectParticipant = (participantToRemove) => {
    const updatedParticipants = data.participants.filter(
      (participant) => participant.name !== participantToRemove.name
    );
    updateWorkshop(data.id, {
      participants: updatedParticipants,
    });
  };

  const handleRequestNewSuggestions = () => {
    updateWorkshop(data.id, { nextStep: "suggestParticipants" });
  };

  const handleProceed = () => {
    updateWorkshop(data.id, {
      currentState: "suggestFeatures",
      nextStep: "suggestFeatures",
    });
  };

  const handleEditParticipant = (participant) => {
    setEditingParticipant({ ...participant });
  };

  const handleSaveEdit = () => {
    const updatedParticipants = data.participants.map((participant) =>
      participant.name === editingParticipant.name
        ? editingParticipant
        : participant
    );
    updateWorkshop(data.id, { participants: updatedParticipants });
    setEditingParticipant(null);
  };

  const isParticipantSelected = (participant) => {
    return (
      data.participants &&
      data.participants.some((p) => p.name === participant.name)
    );
  };

  const unselectedParticipants = data.suggestedParticipants
    ? data.suggestedParticipants.filter(
        (participant) => !isParticipantSelected(participant)
      )
    : [];

  return (
    <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Suggested Participants
        </Typography>
        <Box>
          {unselectedParticipants.length > 0 && (
            <StyledButton
              variant="outlined"
              onClick={handleSelectAllParticipants}
              startIcon={<PersonAddIcon />}
              sx={{ mr: 2 }}
            >
              Select All
            </StyledButton>
          )}
          <StyledButton
            variant="outlined"
            onClick={handleRequestNewSuggestions}
            startIcon={<RefreshIcon />}
          >
            Refresh
          </StyledButton>
        </Box>
      </Box>
      {data.nextStep === "suggestParticipants" ? (
        <InlineProgress message="Generating new participant suggestions..." />
      ) : data.suggestedParticipants ? (
        <List>
          {data.suggestedParticipants
            .filter((participant) => !isParticipantSelected(participant))
            .map((participant, index) => (
              <StyledPaper key={index} elevation={3} sx={{ mb: 3, p: 3 }}>
                <ListItem alignItems="flex-start" sx={{ flexDirection: "column", p: 0 }}>
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {participant.name} - {participant.role}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
                          <strong>Expertise:</strong> {participant.expertise}
                        </Typography>
                        <Typography variant="body2" color="primary">
                          <strong>Value to Workshop:</strong> {participant.valueToWorkshop}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
                <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                  <StyledButton
                    variant="contained"
                    onClick={() => handleSelectParticipant(participant)}
                    startIcon={<PersonAddIcon />}
                  >
                    Select This Participant
                  </StyledButton>
                </Box>
              </StyledPaper>
            ))}
        </List>
      ) : (
        <Typography variant="body1" color="text.secondary">No suggested participants yet.</Typography>
      )}
      <Typography variant="h5" sx={{ mt: 4, mb: 3, fontWeight: 600 }}>
        Selected Participants
      </Typography>
      {data.participants && data.participants.length > 0 ? (
        <List>
          {data.participants.map((participant, index) => (
            <StyledPaper key={index} elevation={2} sx={{ mb: 2, p: 2 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemText
                  primary={`${participant.name} - ${participant.role}`}
                  secondary={participant.expertise}
                />
                <Box>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEditParticipant(participant)}
                    sx={{ mr: 1 }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleUnselectParticipant(participant)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ListItem>
            </StyledPaper>
          ))}
        </List>
      ) : (
        <Typography variant="body1" color="text.secondary">No participants selected yet.</Typography>
      )}
      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        <StyledButton
          variant="outlined"
          onClick={handleRequestNewSuggestions}
          startIcon={<RefreshIcon />}
        >
          Request New Suggestions
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={handleProceed}
          disabled={!data.participants || data.participants.length === 0}
          endIcon={<ArrowForwardIcon />}
        >
          Proceed to Suggest Features
        </StyledButton>
      </Box>

      <StyledDialog
        open={!!editingParticipant}
        onClose={() => setEditingParticipant(null)}
      >
        <DialogTitle>Edit Participant</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={editingParticipant?.name || ""}
            onChange={(e) =>
              setEditingParticipant({
                ...editingParticipant,
                name: e.target.value,
              })
            }
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Role"
            fullWidth
            value={editingParticipant?.role || ""}
            onChange={(e) =>
              setEditingParticipant({
                ...editingParticipant,
                role: e.target.value,
              })
            }
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Expertise"
            fullWidth
            value={editingParticipant?.expertise || ""}
            onChange={(e) =>
              setEditingParticipant({
                ...editingParticipant,
                expertise: e.target.value,
              })
            }
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Value to Workshop"
            fullWidth
            value={editingParticipant?.valueToWorkshop || ""}
            onChange={(e) =>
              setEditingParticipant({
                ...editingParticipant,
                valueToWorkshop: e.target.value,
              })
            }
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setEditingParticipant(null)}>Cancel</StyledButton>
          <StyledButton onClick={handleSaveEdit} variant="contained">Save</StyledButton>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
}

export default SuggestParticipantsStep;
