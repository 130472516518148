import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InlineProgress from '../components/InlineProgress';
import { styled } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SpeedIcon from '@mui/icons-material/Speed';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GroupIcon from '@mui/icons-material/Group';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from 'framer-motion';

const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(5),
  padding: theme.spacing(1.5, 4),
  fontWeight: 600,
}));

function GenerateWorkshopReportStep({ data, updateWorkshop }) {
  const handleFinish = () => {
    updateWorkshop(data.id, { currentState: 'completed', nextStep: null });
  };

  if (data.nextStep === 'generateWorkshopReport') {
    return <InlineProgress message="Generating workshop report..." />;
  }

  if (!data.workshopReport) {
    return <Typography>Workshop report not generated yet.</Typography>;
  }

  const { workshopReport, mvpFeatures, fullProductFeatures } = data;

  return (
    <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, textAlign: 'center', fontWeight: 700 }}>Workshop Report</Typography>
      
      <StyledCard>
        <CardContent>
          <SectionTitle variant="h6"><AssignmentIcon color="primary" /> Executive Summary</SectionTitle>
          <Typography>{workshopReport.executiveSummary}</Typography>
        </CardContent>
      </StyledCard>

      <StyledCard>
        <CardContent>
          <SectionTitle variant="h6"><ErrorOutlineIcon color="primary" /> Problem Statement</SectionTitle>
          <Typography>{workshopReport.problemStatement}</Typography>
        </CardContent>
      </StyledCard>

      <StyledCard>
        <CardContent>
          <SectionTitle variant="h6"><LightbulbIcon color="primary" /> Solution Overview</SectionTitle>
          <Typography>{workshopReport.solutionOverview}</Typography>
        </CardContent>
      </StyledCard>

      <StyledCard>
        <CardContent>
          <SectionTitle variant="h6"><SpeedIcon color="primary" /> MVP</SectionTitle>
          <Typography><strong>Timeline:</strong> {workshopReport.mvp.timeline}</Typography>
          <Typography sx={{ mt: 2 }}><strong>Key Milestones:</strong></Typography>
          <ul>
            {workshopReport.mvp.keyMilestones.map((milestone, index) => (
              <li key={index}>{milestone}</li>
            ))}
          </ul>
          <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>MVP Features:</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Feature</TableCell>
                  <TableCell>Implementation Details</TableCell>
                  <TableCell>Estimated Effort</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mvpFeatures.map((feature, index) => (
                  <StyledTableRow key={index}>
                    <TableCell>{feature.shortDescription}</TableCell>
                    <TableCell>{feature.implementationDetails}</TableCell>
                    <TableCell>{feature.estimatedEffort}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </StyledCard>

      <StyledCard>
        <CardContent>
          <SectionTitle variant="h6"><VisibilityIcon color="primary" /> Full Product</SectionTitle>
          <Typography><strong>Vision:</strong> {workshopReport.fullProduct.vision}</Typography>
          <Typography sx={{ mt: 2 }}><strong>Roadmap:</strong> {workshopReport.fullProduct.roadmap}</Typography>
          <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>Full Product Features:</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Feature</TableCell>
                  <TableCell>Potential Impact</TableCell>
                  <TableCell>Dependencies</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fullProductFeatures.map((feature, index) => (
                  <StyledTableRow key={index}>
                    <TableCell>{feature.shortDescription}</TableCell>
                    <TableCell>{feature.potentialImpact}</TableCell>
                    <TableCell>{feature.dependencies.join(', ')}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </StyledCard>

      <StyledCard>
        <CardContent>
          <SectionTitle variant="h6"><GroupIcon color="primary" /> Participant Insights</SectionTitle>
          <ul>
            {workshopReport.participantInsights.map((insight, index) => (
              <li key={index}>{insight}</li>
            ))}
          </ul>
        </CardContent>
      </StyledCard>

      <StyledCard>
        <CardContent>
          <SectionTitle variant="h6"><ArrowForwardIcon color="primary" /> Next Steps</SectionTitle>
          <ul>
            {workshopReport.nextSteps.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ul>
        </CardContent>
      </StyledCard>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6, mb: 4 }}>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleFinish}
          size="large"
          startIcon={<CheckCircleIcon />}
        >
          Finish Workshop
        </StyledButton>
      </Box>
    </Box>
  );
}

export default GenerateWorkshopReportStep;