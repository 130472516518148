import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InlineProgress from "../components/InlineProgress";
import { styled } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "12px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px",
  padding: theme.spacing(1, 3),
  textTransform: "none",
  fontWeight: 600,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "12px",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.3)",
  },
}));

function SuggestFeaturesStep({ data, updateWorkshop }) {
  const [editingFeature, setEditingFeature] = useState(null);
  const [features, setFeatures] = useState(data.rawSuggestedFeatures || []);

  useEffect(() => {
    setFeatures(data.rawSuggestedFeatures || []);
  }, [data.rawSuggestedFeatures]);

  const handleEditFeature = (feature) => {
    setEditingFeature({ ...feature });
  };

  const handleSaveEdit = () => {
    const updatedFeatures = features.map((feature) =>
      feature.shortDescription === editingFeature.shortDescription
        ? editingFeature
        : feature
    );
    setFeatures(updatedFeatures);
    setEditingFeature(null);
  };

  const handleRemoveFeature = (featureToRemove) => {
    const updatedFeatures = features.filter(
      (feature) => feature.shortDescription !== featureToRemove.shortDescription
    );
    setFeatures(updatedFeatures);
  };

  const handleRequestNewSuggestions = () => {
    updateWorkshop(data.id, { nextStep: "suggestFeatures" });
  };

  const handleProceed = () => {
    updateWorkshop(data.id, {
      rawSuggestedFeatures: features,
      nextStep: "categorizeAndStreamlineFeatures",
    });
  };

  return (
    <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 600 }}>
        Suggested Features
      </Typography>
      {data.nextStep === "suggestFeatures" ? (
        <InlineProgress message="Generating new feature suggestions..." />
      ) : features.length > 0 ? (
        <AnimatePresence>
          <List>
            {features.map((feature, index) => (
              <motion.div
                key={feature.shortDescription}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <StyledPaper elevation={3} sx={{ mb: 3, p: 3 }}>
                  <ListItem alignItems="flex-start" sx={{ flexDirection: "column", p: 0 }}>
                    <ListItemText
                      primary={<Typography variant="h6">{feature.shortDescription}</Typography>}
                      secondary={
                        <>
                          <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
                            <strong>Participant:</strong> {feature.participantName}
                          </Typography>
                          <Typography variant="body2">{feature.longDescription}</Typography>
                        </>
                      }
                    />
                    <Box sx={{ mt: 2, alignSelf: "flex-end" }}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEditFeature(feature)}
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleRemoveFeature(feature)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                </StyledPaper>
              </motion.div>
            ))}
          </List>
        </AnimatePresence>
      ) : (
        <Typography variant="body1" color="text.secondary">No suggested features yet.</Typography>
      )}
      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        <StyledButton
          variant="outlined"
          onClick={handleRequestNewSuggestions}
          startIcon={<RefreshIcon />}
        >
          Request New Suggestions
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={handleProceed}
          disabled={features.length === 0}
          endIcon={<ArrowForwardIcon />}
        >
          Proceed to Categorization
        </StyledButton>
      </Box>

      <StyledDialog open={!!editingFeature} onClose={() => setEditingFeature(null)}>
        <DialogTitle>Edit Feature</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Short Description"
            fullWidth
            value={editingFeature?.shortDescription || ""}
            onChange={(e) =>
              setEditingFeature({
                ...editingFeature,
                shortDescription: e.target.value,
              })
            }
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Long Description"
            fullWidth
            multiline
            rows={4}
            value={editingFeature?.longDescription || ""}
            onChange={(e) =>
              setEditingFeature({
                ...editingFeature,
                longDescription: e.target.value,
              })
            }
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Participant Name"
            fullWidth
            value={editingFeature?.participantName || ""}
            onChange={(e) =>
              setEditingFeature({
                ...editingFeature,
                participantName: e.target.value,
              })
            }
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setEditingFeature(null)}>Cancel</StyledButton>
          <StyledButton onClick={handleSaveEdit} variant="contained">Save</StyledButton>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
}

export default SuggestFeaturesStep;