import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiFormControlLabel-root': {
    marginRight: theme.spacing(3),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: theme.spacing(1, 3),
}));

const models = [
  { name: 'OpenAI GPT-4o-mini', provider: 'openai', model: 'gpt-4o-mini' },
  { name: 'OpenAI GPT-4o', provider: 'openai', model: 'gpt-4o' },
  { name: 'OpenAI GPT-4Turbo', provider: 'openai', model: 'gpt-4-turbo' },
  { name: 'Anthropic Claude 3.5 Sonnet', provider: 'anthropic', model: 'claude-3-5-sonnet-20240620' },
];

function NewWorkshopDialog({ open, onClose, db, onWorkshopAdded, userId }) {
  const [workshopType, setWorkshopType] = useState('area');
  const [inputValue, setInputValue] = useState('');
  const [selectedModel, setSelectedModel] = useState(models[0].model);
  const [provider, setProvider] = useState(models[0].provider);

  const handleModelChange = (e) => {
    const model = models.find(m => m.model === e.target.value);
    setSelectedModel(model.model);
    setProvider(model.provider);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    try {
      const docRef = await addDoc(collection(db, 'workshop'), {
        [workshopType]: inputValue,
        currentState: workshopType === 'area' ? 'suggestIdeas' : 'suggestParticipants',
        nextStep: workshopType === 'area' ? 'suggestIdeas' : 'suggestParticipants',
        userId: userId,
        provider: provider,
        model: selectedModel,
      });
      console.log("Document written with ID: ", docRef.id);
      setInputValue('');
      onWorkshopAdded(docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      component={motion.div}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
    >
      <StyledDialogTitle>Create New Workshop</StyledDialogTitle>
      <StyledDialogContent>
        <StyledRadioGroup
          row
          value={workshopType}
          onChange={(e) => setWorkshopType(e.target.value)}
        >
          <FormControlLabel value="area" control={<Radio color="primary" />} label="Area" />
          <FormControlLabel value="idea" control={<Radio color="primary" />} label="Idea" />
        </StyledRadioGroup>
        <TextField
          autoFocus
          margin="dense"
          label={`Enter ${workshopType}`}
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          required
          variant="outlined"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="model-select-label">Select Model</InputLabel>
          <Select
            labelId="model-select-label"
            value={selectedModel}
            onChange={handleModelChange}
            variant="outlined"
          >
            {models.map((model) => (
              <MenuItem key={model.model} value={model.model}>
                {model.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton onClick={onClose} startIcon={<CloseIcon />}>
          Cancel
        </StyledButton>
        <StyledButton onClick={handleSubmit} variant="contained" color="primary" startIcon={<AddIcon />}>
          Create Workshop
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
}

export default NewWorkshopDialog;
