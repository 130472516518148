import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  doc,
  onSnapshot,
  connectFirestoreEmulator,
  query,
  where,
} from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import "./App.css";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import SpeedIcon from "@mui/icons-material/Speed";
import InsightsIcon from "@mui/icons-material/Insights";
import { ThemeProvider, CssBaseline, styled } from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import CardActions from "@mui/material/CardActions";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import LinearProgress from "@mui/material/LinearProgress";
import { motion } from "framer-motion";

import NewWorkshopDialog from "./NewWorkshopDialog";
import WorkshopVisualizer from "./WorkshopVisualizer";
import workshopTheme from "./theme";

// Initialize Firebase (replace with your own config)
const firebaseConfig = {
  apiKey: "AIzaSyDbmSc-KvfuP40NBODy1C1F7q5fHF45EUY",
  authDomain: "full-cycle-ai.firebaseapp.com",
  projectId: "full-cycle-ai",
  storageBucket: "full-cycle-ai.appspot.com",
  messagingSenderId: "751221642433",
  appId: "1:751221642433:web:f27e21dae206feb31cf040",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const auth = getAuth(app);

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const HeroSection = styled(Box)(({ theme }) => ({
  
  color: theme.palette.common.white,
  padding: theme.spacing(12, 0, 15),
  position: "relative", // Ensures the pseudo-element is positioned correctly

}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1.5, 4),
  fontSize: "1.1rem",
  fontWeight: 600,
  borderRadius: "30px",
  textTransform: "none",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    background: theme.palette.primary.light,
    transform: "translateY(-3px)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.25)",
  },
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  boxShadow: "none",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  transition: "all 0.3s ease-in-out",
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  color: "#fff",
  borderRadius: "50%",
  height: theme.spacing(8),
  width: theme.spacing(8),
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const HomeButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Connect to Firebase Emulators in development
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}

function App() {
  const [workshops, setWorkshops] = useState([]);
  const [selectedWorkshopId, setSelectedWorkshopId] = useState("");
  const [workshopData, setWorkshopData] = useState(null);
  const [isNewWorkshopDialogOpen, setIsNewWorkshopDialogOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchWorkshops(currentUser.uid);
      } else {
        setWorkshops([]);
        setSelectedWorkshopId("");
        setWorkshopData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (selectedWorkshopId) {
      const unsubscribe = onSnapshot(
        doc(db, "workshop", selectedWorkshopId),
        (doc) => {
          if (doc.exists()) {
            setWorkshopData({ id: doc.id, ...doc.data() });
          } else {
            console.log("No such document!");
          }
        }
      );

      return () => unsubscribe();
    }
  }, [selectedWorkshopId]);

  const fetchWorkshops = async (userId) => {
    const workshopCollection = collection(db, "workshop");
    const q = query(workshopCollection, where("userId", "==", userId));
    const workshopSnapshot = await getDocs(q);
    const workshopList = workshopSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setWorkshops(workshopList);
  };

  const handleWorkshopSelect = (workshopId) => {
    setSelectedWorkshopId(workshopId);
    const selectedWorkshop = workshops.find(
      (workshop) => workshop.id === workshopId
    );
    setWorkshopData(selectedWorkshop);
  };

  const updateWorkshop = async (id, data) => {
    const workshopRef = doc(db, "workshop", id);
    await updateDoc(workshopRef, data);
  };

  const handleWorkshopAdded = (newWorkshopId) => {
    fetchWorkshops(user.uid);
    setSelectedWorkshopId(newWorkshopId);
    setIsNewWorkshopDialogOpen(false);
  };

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const handleHomeClick = () => {
    setSelectedWorkshopId("");
    setWorkshopData(null);
  };

  const LandingPage = ({ handleLogin }) => {
    const features = [
      {
        icon: <EmojiObjectsIcon fontSize="large" />,
        title: "AI-Powered Ideation",
        description:
          "Generate innovative ideas with AI simulating diverse participant roles.",
      },
      {
        icon: <SpeedIcon fontSize="large" />,
        title: "Rapid Execution",
        description:
          "Complete full workshops in minutes with streamlined AI tools.",
      },
      {
        icon: <InsightsIcon fontSize="large" />,
        title: "In-Depth Insights",
        description:
          "Reports with actionable insights and development roadmaps.",
      },
    ];

    return (
      <Box>
        <HeroSection>
          <Container>
          <Typography
                    variant="h1"
                    component="h1"
                    gutterBottom
                    fontWeight="normal"
                  >
                    Work-chop-chop
                  </Typography>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <Typography
                    variant="h2"
                    component="h1"
                    gutterBottom
                    fontWeight="bold"
                  >
                    AI Workshops
                    <br/>
                    <span style={{ color: workshopTheme.palette.secondary.main }}>No Team Needed</span>

                  </Typography>
                  <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    sx={{ mb: 4 }}
                  >
                    Harness the power of AI to conduct ultra-efficient,
                    insightful workshops in a fraction of the time.
                  </Typography>
                  <StyledButton onClick={handleLogin} size="large">
                    Get Started Now
                  </StyledButton>
                </motion.div>
              </Grid>
              <Grid item xs={12} md={6}>
                <motion.img
                  src="/hero.jpg"
                  alt="AI Workshop Platform"
                  style={{
                    width: "100%",
                    maxWidth: 600,
                    borderRadius: 16, // Adjust the value to change the rounding radius
                    overflow: "hidden", // Ensures content doesn't overflow the rounded border
                  }}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                />
              </Grid>
            </Grid>
          </Container>
        </HeroSection>

        <Container sx={{ mt: -10, mb: 10, position: "relative", zIndex: 1 }}>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <FeatureCard>
                    <IconWrapper>{feature.icon}</IconWrapper>
                    <Typography
                      variant="h5"
                      component="h3"
                      gutterBottom
                      align="center"
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      align="center"
                    >
                      {feature.description}
                    </Typography>
                  </FeatureCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>

      </Box>
    );
  };

  const WorkshopCard = ({ workshop }) => {
    const progress = calculateWorkshopProgress(workshop);

    return (
      <Card>
        <CardContent>
          <Typography variant="h6" component="div" gutterBottom>
            {workshop.area || workshop.ideaName || "Untitled Workshop"}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {workshop.idea || "No description"}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {`Model: ${workshop.model || "Unknown"} (${
              workshop.provider || "Unknown"
            })`}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ mt: 2 }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
            align="right"
            sx={{ mt: 1 }}
          >
            {`${Math.round(progress)}% Complete`}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={() => handleWorkshopSelect(workshop.id)}
          >
            Open Workshop
          </Button>
        </CardActions>
      </Card>
    );
  };

  const HomePage = () => (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Your Workshops
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsNewWorkshopDialogOpen(true)}
        sx={{ mb: 4 }}
      >
        New Workshop
      </Button>
      {workshops && workshops.length > 0 ? (
        <Grid container spacing={3}>
          {workshops.map((workshop) => (
            <Grid item xs={12} sm={6} md={4} key={workshop.id}>
              <WorkshopCard workshop={workshop} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" gutterBottom>
            You don't have any workshops yet.
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            Click the "New Workshop" button to create your first workshop!
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <ThemeProvider theme={workshopTheme}>
      <CssBaseline />
      <StyledAppBar position="static">
        {user && (
          <Toolbar>
            <HomeButton
              color="inherit"
              hidden={!selectedWorkshopId}
              onClick={handleHomeClick}
              startIcon={<HomeIcon />}
            >
              Home
            </HomeButton>
            <Typography
              variant="h1"
              component="div"
              sx={{ flexGrow: 1, fontWeight: 600, fontSize: "1.5rem" }}
            >
              {selectedWorkshopId
                ? `Workshop: ${
                    workshopData?.area || workshopData?.ideaName || "Untitled"
                  }`
                : "Work-chop-chop"}
            </Typography>
            {user ? (
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <Button color="inherit" onClick={handleLogin}>
                Login with Google
              </Button>
            )}
          </Toolbar>
        )}
      </StyledAppBar>
      <StyledContainer maxWidth="lg">
        <Box sx={{ position: "relative", minHeight: "calc(100vh - 64px)" }}>
          {user ? (
            selectedWorkshopId ? (
              <WorkshopVisualizer
                data={workshopData}
                updateWorkshop={updateWorkshop}
              />
            ) : (
              <HomePage />
            )
          ) : (
            <LandingPage handleLogin={handleLogin} />
          )}
        </Box>
      </StyledContainer>
      <NewWorkshopDialog
        open={isNewWorkshopDialogOpen}
        onClose={() => setIsNewWorkshopDialogOpen(false)}
        db={db}
        onWorkshopAdded={handleWorkshopAdded}
        userId={user ? user.uid : null}
      />
    </ThemeProvider>
  );
}

// Helper function to calculate workshop progress
function calculateWorkshopProgress(workshop) {
  const steps = [
    "defineArea",
    "suggestIdeas",
    "selectIdea",
    "suggestParticipants",
    "selectParticipants",
    "suggestFeatures",
    "categorizeAndStreamlineFeatures",
    "performDotVoting",
    "performMoSCoWAnalysis",
    "defineMVPFeatures",
    "defineFullProductFeatures",
    "generateWorkshopReport",
    "completed",
  ];

  const currentStateIndex = steps.indexOf(workshop.currentState);
  const totalSteps = steps.length;

  if (currentStateIndex === -1) {
    return 0; // Workshop hasn't started or is in an unknown state
  }

  return ((currentStateIndex + 1) / totalSteps) * 100;
}

export default App;
