import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InlineProgress from '../components/InlineProgress';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: theme.spacing(1, 3),
  textTransform: 'none',
  fontWeight: 600,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
  },
}));

function SuggestIdeasStep({ data, updateWorkshop }) {
  const [editingIdea, setEditingIdea] = useState(null);

  const handleSelectIdea = (idea) => {
    updateWorkshop(data.id, {
      ideaName: idea.title,
      idea: idea.description,
      currentState: 'suggestParticipants',
      nextStep: 'suggestParticipants'
    });
  };

  const handleGenerateNewIdeas = () => {
    updateWorkshop(data.id, { nextStep: 'suggestIdeas' });
  };

  const handleEditIdea = (idea) => {
    setEditingIdea({ ...idea });
  };

  const handleSaveEdit = () => {
    const updatedIdeas = data.suggestedIdeas.map(idea => 
      idea.title === editingIdea.title ? editingIdea : idea
    );
    updateWorkshop(data.id, { suggestedIdeas: updatedIdeas });
    setEditingIdea(null);
  };

  return (
    <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 3, fontWeight: 600 }}>Suggested Ideas</Typography>
      {data.nextStep === 'suggestIdeas' ? (
        <InlineProgress message="Generating ideas..." />
      ) : data.suggestedIdeas ? (
        <List>
          {data.suggestedIdeas.map((idea, index) => (
            <StyledPaper key={index} elevation={3} sx={{ mb: 3, p: 3 }}>
              <ListItem alignItems="flex-start" sx={{ flexDirection: 'column', p: 0 }}>
                <ListItemText
                  primary={<Typography variant="h6" sx={{ mb: 1 }}>{idea.title}</Typography>}
                  secondary={
                    <>
                      <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                        {idea.description}
                      </Typography>
                      <Typography variant="body2" color="primary">
                        <strong>Potential Impact:</strong> {idea.potentialImpact}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <StyledButton 
                  variant="contained" 
                  onClick={() => handleSelectIdea(idea)} 
                  startIcon={<CheckCircleIcon />}
                  sx={{ mr: 1 }}
                >
                  Select This Idea
                </StyledButton>
                <StyledButton 
                  variant="outlined" 
                  onClick={() => handleEditIdea(idea)}
                  startIcon={<EditIcon />}
                >
                  Edit
                </StyledButton>
              </Box>
            </StyledPaper>
          ))}
        </List>
      ) : (
        <Typography variant="body1" color="text.secondary">No suggested ideas yet.</Typography>
      )}
      <StyledButton 
        variant="outlined" 
        onClick={handleGenerateNewIdeas} 
        startIcon={<AutorenewIcon />}
        sx={{ mt: 3 }}
      >
        Generate New Ideas
      </StyledButton>

      <StyledDialog open={!!editingIdea} onClose={() => setEditingIdea(null)}>
        <DialogTitle>Edit Idea</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            fullWidth
            value={editingIdea?.title || ''}
            onChange={(e) => setEditingIdea({ ...editingIdea, title: e.target.value })}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={editingIdea?.description || ''}
            onChange={(e) => setEditingIdea({ ...editingIdea, description: e.target.value })}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Potential Impact"
            fullWidth
            value={editingIdea?.potentialImpact || ''}
            onChange={(e) => setEditingIdea({ ...editingIdea, potentialImpact: e.target.value })}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setEditingIdea(null)}>Cancel</StyledButton>
          <StyledButton onClick={handleSaveEdit} variant="contained">Save</StyledButton>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
}

export default SuggestIdeasStep;